import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import axios from 'axios';
import { statusOptions } from '../SubscriptionManagement/useSubscriptionLevel';
import {
  selectGroup,
  selectUser,
  useAppSelector,
} from '../../slices/commonSelectors';
import apiConfig from '../../constants/apiConfig';
import StylishNewSelect from '../DesignSystems/New/StylishNewSelect';

const OrgSubscription: React.FC = () => {
  const selectedGroup = useAppSelector(selectGroup);
  const user = useAppSelector(selectUser);

  const groupSubscription = user.subscriptions?.find(
    (s) => s.group_guid === selectedGroup.group_guid
  );

  const [subscriptionStatus, setSubscriptionStatus] = useState(
    groupSubscription?.status ?? statusOptions[0]
  );

  const createTrialSubscription = useMutation({
    mutationFn: async () => {
      const response = await axios.post(
        apiConfig.apiGateway.createOrgSubscription,
        {
          group_guid: selectedGroup.group_guid,
          details: {
            subscription_type: 'defaults to trial subscription',
          },
        }
      );
      return response.data;
    },
    onSuccess: () => {
      window.location.reload();
    },
  });

  const updateSubscription = useMutation({
    mutationFn: async (status: string) => {
      const response = await axios.post(
        apiConfig.apiGateway.updateOrgSubscription,
        {
          group_guid: selectedGroup.group_guid,
          status: status,
        }
      );
      return response.data;
    },
    onSuccess: () => {
      window.location.reload();
    },
  });

  return (
    <>
      <div className="nav-content-header">Subscription Information</div>
      <div className="card justify-content-between mt-2">
        <div className="d-flex flex-column">
          <div className={'alert'}>
            <strong>Current subscriptions for user</strong>
            <ul>
              {user.subscriptions?.map((subscription) => (
                <li key={subscription.subscription_id} className={'my-1'}>
                  <span>{subscription.group_name}:</span>
                  <span className={'ps-2 text-muted'}>
                    {subscription.status}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          {groupSubscription ? (
            <>
              <StylishNewSelect
                classes={'secondary'}
                options={statusOptions.map((status) => ({
                  label: status,
                  value: status,
                }))}
                value={{
                  label: subscriptionStatus,
                  value: subscriptionStatus,
                }}
                placeholder="Select a Status"
                onChange={(e) => setSubscriptionStatus(e.value)}
              />

              <StylishNewButton
                className={'button--secondary mt-3'}
                onClick={() => updateSubscription.mutate(subscriptionStatus)}
              >
                Save (reloads page)
              </StylishNewButton>
            </>
          ) : null}

          {!groupSubscription ? (
            <StylishNewButton
              className={'button--secondary'}
              onClick={() => createTrialSubscription.mutate()}
            >
              Create Trial Subscription (reloads page)
            </StylishNewButton>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default OrgSubscription;
