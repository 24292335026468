import React, { useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import Collapse from '@mui/material/Collapse';
import {
  ListGroupDropdownItem,
  ListGroupNavItem,
} from './ListGroupNavComponents';
import { useSelector } from 'react-redux';
import {
  useFeatureFlag,
  useStreamlinePlatformFlag,
} from '../../utils/featureFlags';

type ExerciseDropdown = 'design' | 'conduct' | 'assess' | 'settings' | 'msel';

const ExerciseNavPanel: React.FC<{ navBack: () => void }> = ({ navBack }) => {
  const [openDropdown, setOpenDropdown] = useState<ExerciseDropdown | null>(
    null
  );
  const workspace = useSelector((state: any) => {
    return state?.prepare?.workspace;
  });
  const streamlinePlatform = useStreamlinePlatformFlag();
  const toggleDropdown = (dropdown: ExerciseDropdown) => {
    if (openDropdown === dropdown) {
      setOpenDropdown(null);
    } else {
      setOpenDropdown(dropdown);
    }
  };

  return (
    <nav aria-label="secondary sidebar" className="second-panel">
      <div className="second-panel-back">
        <button
          className="button button--icon border-0 bg-bglight"
          onClick={navBack}
        >
          <i className="material-symbols-outlined">arrow_back</i>
        </button>
        <div>Exercise Management</div>
      </div>
      <ListGroup>
        <div className="list-group-title">Plan Exercise</div>
        {workspace.type === 'T&E' ? (
          <>
            <ListGroupNavItem
              to="/prepare/dashboard/plan"
              icon="grid_view"
              label="Dashboard"
              end
            />
            <ListGroupDropdownItem
              onClick={() => toggleDropdown('design')}
              open={openDropdown === 'design'}
              icon="stylus_note"
              label="Design"
            />
            <Collapse in={openDropdown === 'design'}>
              <ListGroupNavItem
                to="/prepare/dashboard/plan/experimental-event-details/details"
                label="Experiment Details"
                secondary
                end
              />
              <ListGroupNavItem
                to="/prepare/dashboard/plan/experimental-event-details/cois"
                label="COIs"
                secondary
              />
              <ListGroupNavItem
                to="/prepare/dashboard/plan/experimental-event-details/event_cells"
                label="Event Cells"
                secondary
              />
              <ListGroupNavItem
                to="/prepare/dashboard/plan/experimental-event-details/funding"
                label="Workgroups"
                secondary
              />
              <ListGroupNavItem
                to="/prepare/dashboard/plan/experimental-event-details/data_collection"
                label="Data Collection Methods"
                secondary
              />
            </Collapse>
            <ListGroupNavItem
              to="/prepare/dashboard/plan/experimental-event-details/jelc_calendar"
              icon="calendar_month"
              label="JELC Calendar"
            />
            <ListGroupNavItem
              to="/prepare/dashboard/plan/experimental-event-details/poam"
              icon="flag"
              label="POA&M"
            />
            <ListGroupNavItem
              to="/prepare/dashboard/plan/trr"
              icon="rate_review"
              label="TRR"
            />
            <ListGroupNavItem
              to="/prepare/dashboard/plan/systems"
              icon="settings_system_daydream"
              label="Systems"
            />
            <ListGroupDropdownItem
              onClick={() => toggleDropdown('conduct')}
              open={openDropdown === 'conduct'}
              icon="directions_run"
              label="Missions"
            />
            <Collapse in={openDropdown === 'conduct'}>
              <ListGroupNavItem
                to="/prepare/dashboard/plan/missions"
                label="Missions"
                secondary
                end
              />
              <ListGroupNavItem
                to="/prepare/dashboard/plan/missions/timeline"
                label="Sorties"
                secondary
                end
              />
            </Collapse>
            <ListGroupNavItem
              to="/prepare/dashboard/plan/missions/mission-timeline"
              icon="view_timeline"
              label="Sortie Timeline"
            />
            <ListGroupNavItem
              to="/prepare/dashboard/plan/missions/analytics"
              icon="bar_chart"
              label="Analytics"
              end
            />
          </>
        ) : (
          <>
            <ListGroupNavItem
              to="/prepare/dashboard/plan"
              icon="dashboard"
              label="Dashboard"
              end
            />
            <ListGroupDropdownItem
              onClick={() => toggleDropdown('design')}
              open={openDropdown === 'design'}
              icon="stylus_note"
              label="Design"
            />
            <Collapse in={openDropdown === 'design'}>
              <ListGroupNavItem
                to="/prepare/dashboard/plan/prepare/dashboard"
                label="Overview (Dashboard)"
                secondary
              />
              <ListGroupNavItem
                to="/prepare/dashboard/plan/prepare/exercise-details"
                label="Exercise Details"
                secondary
              />
              <ListGroupNavItem
                to="/prepare/dashboard/plan/prepare/participants"
                label="Participants"
                secondary
              />
              <ListGroupNavItem
                to="/prepare/dashboard/plan/prepare/training-objective"
                label="Objectives"
                secondary
              />
              <ListGroupNavItem
                to="/prepare/dashboard/plan/prepare/events"
                label="Scenarios"
                secondary
              />
            </Collapse>

            <ListGroupDropdownItem
              onClick={() => toggleDropdown('msel')}
              open={openDropdown === 'msel'}
              icon="event_note"
              label="MSEL"
            />
            <Collapse in={openDropdown === 'msel'}>
              <ListGroupNavItem
                to="/prepare/dashboard/plan/prepare/msel-list"
                label="List"
                secondary
              />
              <ListGroupNavItem
                to="/prepare/dashboard/plan/prepare/msel-board"
                label="Board"
                secondary
              />
              <ListGroupNavItem
                to="/prepare/dashboard/plan/prepare/msel-calendar"
                label="Calendar"
                secondary
              />
            </Collapse>

            <ListGroupDropdownItem
              onClick={() => toggleDropdown('conduct')}
              open={openDropdown === 'conduct'}
              icon="directions_run"
              label="Conduct"
            />
            <Collapse in={openDropdown === 'conduct'}>
              <ListGroupNavItem
                to="/prepare/dashboard/plan/prepare/train/dashboard"
                label="Dashboard"
                secondary
              />
              <ListGroupNavItem
                to="/prepare/dashboard/plan/train/msel-timeline"
                label="MSEL Timeline"
                secondary
              />
            </Collapse>
            <ListGroupDropdownItem
              onClick={() => toggleDropdown('assess')}
              open={openDropdown === 'assess'}
              icon="checklist_rtl"
              label="Assess"
            />
            <Collapse in={openDropdown === 'assess'}>
              <ListGroupNavItem
                to="/prepare/dashboard/plan/assessment/overview"
                label="Overview"
                secondary
              />
              <ListGroupNavItem
                to="/prepare/dashboard/plan/assessment/assess-list"
                label="Assessment List"
                secondary
              />
              <ListGroupNavItem
                to="/prepare/dashboard/plan/feedback"
                label="Feedback"
                secondary
              />
            </Collapse>
            <ListGroupNavItem
              to="/prepare/dashboard/plan/reports/list"
              icon="bar_chart"
              label="Analysis"
            />
          </>
        )}
        {!streamlinePlatform && (
          <ListGroupNavItem
            to="/prepare/dashboard/notebook/notebooks"
            icon="book"
            label="Notebook"
          />
        )}
        <ListGroupDropdownItem
          onClick={() => toggleDropdown('settings')}
          open={openDropdown === 'settings'}
          icon="settings"
          label="Settings"
        />
        <Collapse in={openDropdown === 'settings'}>
          <ListGroupNavItem
            to="/prepare/dashboard/settings/members"
            label="Members"
            secondary
          />
          <ListGroupNavItem
            to="/prepare/dashboard/settings/time-zone"
            label="Time Zones"
            secondary
          />
          <ListGroupNavItem
            to="/prepare/dashboard/settings/jmets"
            label="JMETs"
            secondary
          />
          <ListGroupNavItem
            to="/prepare/dashboard/settings/organizations"
            label="Organizations"
            secondary
          />
          <ListGroupNavItem
            to="/prepare/dashboard/settings/platform"
            label="Platform"
            secondary
          />
          <ListGroupNavItem
            to="/prepare/dashboard/settings/organization-type"
            label="ESF/Organization Type"
            secondary
          />
          <ListGroupNavItem
            to="/prepare/dashboard/settings/service"
            label="City/Town/Service/Department"
            secondary
          />
          <ListGroupNavItem
            to="/prepare/dashboard/settings/department-type"
            label="Jurisdiction/Service/Department Types"
            secondary
          />
          <ListGroupNavItem
            to="/prepare/dashboard/settings/command-agency"
            label="State/County/Chapter/Command/Agency"
            secondary
          />
          <ListGroupNavItem
            to="/prepare/dashboard/settings/strategic-priority"
            label="Strategic Priorities"
            secondary
          />
          <ListGroupNavItem
            to="/prepare/dashboard/settings/inject-method"
            label="Inject Methods"
            secondary
          />
          <ListGroupNavItem
            to="/prepare/dashboard/settings/inject-owner"
            label="Inject Owners"
            secondary
          />
          <ListGroupNavItem
            to="/prepare/dashboard/settings/status-label"
            label="Status Labels"
            secondary
          />
          <ListGroupNavItem
            to="/prepare/dashboard/settings/assessment-reasons"
            label="Assessment Reasons"
            secondary
          />
          <ListGroupNavItem
            to="/prepare/dashboard/settings/training-priority"
            label="Objective Priority"
            secondary
          />
          <ListGroupNavItem
            to="/prepare/dashboard/settings/exercise-type"
            label="Exercise Type"
            secondary
          />
          <ListGroupNavItem
            to="/prepare/dashboard/settings/fromto"
            label="From To"
            secondary
          />
          <ListGroupNavItem
            to="/prepare/dashboard/settings/position"
            label="Position"
            secondary
          />
          <ListGroupNavItem
            to="/prepare/dashboard/settings/metric"
            label="Metric"
            secondary
          />
          <ListGroupNavItem
            to="/prepare/dashboard/settings/primary-source"
            label="Primary Source"
            secondary
          />
          <ListGroupNavItem
            to="/prepare/dashboard/settings/sub-source"
            label="Sub Source"
            secondary
          />
          {workspace.type === 'T&E' ? (
            <>
              <ListGroupNavItem
                to="/prepare/dashboard/settings/support"
                label="Support"
                secondary
              />
              <ListGroupNavItem
                to="/prepare/dashboard/settings/technical-discipline"
                label="Technical Discipline"
                secondary
              />
              <ListGroupNavItem
                to="/prepare/dashboard/settings/mission-type"
                label="Mission Type"
                secondary
              />
              <ListGroupNavItem
                to="/prepare/dashboard/settings/mission-profile"
                label="Mission Profile"
                secondary
              />
              <ListGroupNavItem
                to="/prepare/dashboard/settings/mission-location"
                label="Mission Location"
                secondary
              />
            </>
          ) : null}
        </Collapse>
      </ListGroup>
    </nav>
  );
};

export default ExerciseNavPanel;
